<template>
  <div class="add-payment-auth">
    <CategoryTitle :category="category" />
    <h3 v-if="!confirmed" class="error--text">
      {{ $t("profile.paymentMethods.notConfirmed") }}
    </h3>
    <div>
      <!-- id: 12 monetaweb, 13 monetawebpaypal -->
      <PaymentTypeList
        :modality="'addAuth'"
        :options="paymentTypeListOptions"
        :filteredPaymentTypeIds="config.addAuthPaymentTypeIds"
      />
    </div>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import { mapState } from "vuex";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "MyPaymentMethods",
  mixins: [categoryMixins],
  components: {
    PaymentTypeList,
    CategoryTitle
  },
  data() {
    return {
      config: null,
      paymentAuthList: [],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        hidePrevButton: true,
        showRememberCard: false,
        showAddNewCardWarning: false,
        confirm: {
          label: "Aggiungi nuova carta",
          disabledIfNotConfirmed: "checkout.confirmEmailSubTitle"
        }
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    confirmed() {
      return get(this.cart.user, "profile.confirmed", false);
    }
  },
  created() {
    this.config = global.config;
    this.paymentTypeListOptions.confirm.show = this.confirmed;
  }
};
</script>
